.btn-submit {
    width: 100%;
    background: #040256;
}

.success-contact {
    text-align: center;
    color: green;
    font-weight: bold;
    margin-top: 5px;
    font-size: 18px;
}