.footer-container {
    background-color: var(--primary-color);
    color: #fff;
    padding: 30px 20px;
}

.footer-top {
    display: flex;
    justify-content:center;
    align-items:center;
    text-align: justify;
}

.footer-top img {
    max-width: 300px;
}

.footer-bottom {
    padding-top: 30px
}

.menu-footer {
    list-style: none;
}

.link-footer {
    color: #fff;
    text-decoration: none;
}

.link-footer:hover {
    color: #fff;
    text-decoration: underline;
}