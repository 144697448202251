.header-top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 20px
}

.header-top-left {
    text-align: center;
    color: var(--primary-color);
}

.header-top-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.header-top-right {
    text-align: center;
    color: var(--primary-color);
}

.container-menu {
    border-top: 1px solid var(--primary-color);
}