.links-breadcrumbs a {
    color: #000;
}

.back-breadcrumbs {
    display: flex;
    align-items: center;
    height:50px;
    background: rgb(236,236,236);
    background: linear-gradient(0deg, rgba(236,236,236,1) 0%, rgba(245,245,245,1) 100%);
}