.item-menu {
    text-decoration: none;
    color: #000;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.item-menu:hover {
    color: #000
}

@media screen and (max-width: 768px) {
    .item-menu {
        border-bottom: 1px solid #000
    }
    .item-menu:active {
        background-color: #ddd
    }
}

@media screen and (min-width: 768px) {
    .item-menu::after {
        display: block;
        content: '';
        border-bottom: solid 3px var(--primary-color);  
        transform: scaleX(0);  
        transition: transform 250ms ease-in-out;
        
    }
    
    .item-menu:hover:after { 
        transform: scaleX(1); 
    }
    
    .item-menu::after {  
        transform-origin:  0% 50%; 
    } 
}